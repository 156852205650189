#root {
    overflow:hidden;
    position: relative;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    
    height: 100vh;
    
    background: linear-gradient(to right, rgba(36,31,31,1) 0%, rgba(36,31,31,1) 32%, rgba(74,71,70,1) 100%);
    color: #fff;
    text-align: center;
  }
  
.vignette{
    position:absolute;
    z-index: 99;
    left: 0px;
    top: 0px;
    width:100%; height:100vh;
    box-shadow:inset 0px 0px 150px 20px black;
    mix-blend-mode: multiply;
    -webkit-animation: vignette-anim 3s infinite; /* Safari 4+ */
    -moz-animation:    vignette-anim 3s infinite; /* Fx 5+ */
    -o-animation:      vignette-anim 3s infinite; /* Opera 12+ */
    animation:         vignette-anim 3s infinite; /* IE 10+, Fx 29+ */
    pointer-events: none;
    @media screen and (max-width: $sm){
      box-shadow:inset 0px 0px 40px 15px black;
    }
}

.noise {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    opacity: .15;
}

.line{
    position:absolute;
    height:100%; width:1px;
    opacity:0.1;
    background-color:#000;
}

@-webkit-keyframes vignette-anim {
    0%   , 100%{ opacity: 1; }
    50% { opacity: 0.7; }
}
@-moz-keyframes vignette-anim {
    0%   , 100%{ opacity: 1; }
    50% { opacity: 0.7; }
}
@-o-keyframes vignette-anim {
    0%   , 100%{ opacity: 1; }
    50% { opacity: 0.7; }
}
@keyframes vignette-anim {
    0%   , 100%{ opacity: 1; }
    50% { opacity: 0.7; }
}

@keyframes gradientSize {
  0%{opacity: 0.3;}
  10%{opacity: 0.4;}
  20%{opacity: 0.25;}
  30%{opacity: 0.5;}
  40%{opacity: 0.75;}
  50%{opacity: 0.5;}
  60%{opacity: 1;}
  70%{opacity: 0.75}
  80%{opacity: 0.5;}
  90%{opacity: 0.25;}
  100%{opacity: 0.5;}
}
@keyframes moveLine{
  0%{left: 0; opacity: 0;}
  9%{left: 0; opacity: 0;}
  10%{left: 15%; opacity: 0.25;}
  15%{left: 20%; opacity: 0.1;}
  20%{left: 10%; opacity: 0.2;}
  25%{left: 25%; opacity: 0.2;}
  30%{left: 30%; opacity: 0.3;}
  40%{left: 20%; opacity: 0.2;}
  50%{left: 30%; opacity: 0.1;}
  60%{left: 20%; opacity: 0.2;}
  70%{left: 0%; opacity: 0.2;}
  80%{left: 10%; opacity: 0.2;}
  90%{left: 30%; opacity: 0.3;}
  100%{left: 10%; opacity: 0.1;}
}
@keyframes bgMove{
  0%{background-position: 0px center;}
  5%{background-position: 0 center;}
  10%{background-position: 0 -2px;}
  15%{background-position: 0 center;}
  30%{background-position: 0px center;}
  37%{background-position: 0 -2px;}
  40%{background-position: 0px center;}
  45%{background-position: 0 2px;}
  50%{background-position: 0px center;}
  62%{background-position: 0px center;}
  65%{background-position: 0 -2px;}
  67%{background-position: 0px center;}
  78%{background-position: 0px center;}
  80%{background-position: 0 2px;}
  85%{background-position: 0 center;}
  87%{background-position: 0 center;}
  89%{background-position: 0 -2px;}
  91%{background-position: 0 center;}
  100%{background-position: 0px center;}
}
#effects{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
    &:before{
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation-name: gradientSize;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      -moz-box-shadow: inset 0 0 10em rgba(0,0,0,1);  
      -webkit-box-shadow: inset 0 0 10em rgba(0,0,0,1);  
       box-shadow: inset 0 0 10em rgba(0,0,0,1);
    }
    &:after{
      width: 1px;
      height: 100%;
      background: rgba(0,0,0,1);
      content: ' ';
      display: block;
      position: absolute;
      animation-name: moveLine;
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
  }

  //Button effect
   
@mixin button($bcolor,
$url,
$x1,
$y1,
$bor,
$col) {
background: $bcolor;
-webkit-mask: url($url);
mask: url($url);
-webkit-mask-size: $x1 $y1;
mask-size: $x1 $y1;
border: $bor;
color: $col;
}

.button-container {
    position: relative;
    width: 150px;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6vh;
    overflow: hidden;
    border: none;
    font-weight: 300;
    font-size: 20px;
    transition: 0.5s;
    letter-spacing: 1px;
    border-radius: 0px;
    .mas{
        position: absolute;
        color: $primary;
        text-align: center;
        width: 101%;
        font-weight: 300;
        position: absolute;
        font-size: 20px;
        line-height: 80px;
        text-transform: uppercase;
        font-family: $font-secondary;
        overflow: hidden;
        font-weight: bold;
    }
    button {
        width: 101%;
        height: 100%;
        font-weight: 300;
        font-size: 20px;
        line-height: 78px;
        text-transform: uppercase;
        font-family: $font-secondary;
        letter-spacing: 1px;
        font-weight: bold;

        @include button($primary,
        "/blood-sprite.png",
        2300%,
        100%,
        none,
        #fff);
        cursor: pointer;
        -webkit-animation: ani2 0.7s steps(22) forwards;
        animation: ani2 0.7s steps(22) forwards;
        &:hover {
        -webkit-animation: ani 0.7s steps(22) forwards;
        animation: ani 0.7s steps(22) forwards;
        }
    }
}
   
   @keyframes ani {
    from {
     -webkit-mask-position: 0 0;
     mask-position: 0 0;
    }
   
    to {
     -webkit-mask-position: 100% 0;
     mask-position: 100% 0;
    }
   }
   
   @keyframes ani2 {
    from {
     -webkit-mask-position: 100% 0;
     mask-position: 100% 0;
    }
   
    to {
     -webkit-mask-position: 0 0;
     mask-position: 0 0;
    }
   }


//BLOOD DRIP

@mixin leftPos($count){
  @for $i from 1 through $count {
      &:nth-child(#{$i}) {
  		left:((($i)-2)*2)-1%;
	  }
  }
}

.liquid{
  width:120%;
  height:10px;
  position:absolute;
  top:-2%;
  left:-10%;
  background: $primary;
  border-radius:10%;
  animation: liquid 5s forwards;
}

.blobs{
  filter:url('/images/blood.svg');
  width: 100%;
  height: 100vh;
  position:fixed;
  top: 0px;
  left: 0px;
  .blob{
    width:30px;
    height:30px;
    margin:0 5px 0px 0;
    background: $primary;
    border-radius:50%;
    position:absolute;
    top:0;
    @include leftPos(60);
    animation: drip_one 5s forwards;
      
      &:nth-of-type(4n-7){
        width:65px;
      	animation: drip_four 5s forwards;
        } 
      &:nth-of-type(3n-2){
        width:26px;
        }
      &:nth-of-type(2){
      	width:22px;
    	
      }
  	  &:nth-of-type(8){
        animation: drip_five 5s forwards;
        }
      &:nth-of-type(4n+2){
        height:56px;
        animation: drip_two 5s forwards;
        }
      &:nth-of-type(6n-2){
        height:42px;
    	animation: drip_three 5s forwards;
        }
    }

  }

@keyframes drip_one{
  from{top:0;}
  to{top:103%;}
  }  
@keyframes drip_two{
  from{top:0;}
  to{top:104%;}
  }
@keyframes drip_three{
  from{top:0;height:52px;}
  to{top:102%;height:132px;}
  } 
@keyframes drip_four{
  from{top:0;width:65px;height:30px;}
  to{top:102%;width:75px;height:45px;}
  } 
@keyframes drip_five{
  from{top:0;height:30px;}
  to{top:102%;height:72px;}
  } 
@keyframes liquid{
  from{height:15px;}
  to{height:109%;}
  }
   
  
