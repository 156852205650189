$flaticon-font: "flaticon";

@font-face {
    font-family: $flaticon-font;
    src: url("/fonts/Flaticon.eot");
    src: url("/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Flaticon.woff2") format("woff2"),
    url("/fonts/Flaticon.woff") format("woff"),
    url("/fonts/Flaticon.ttf") format("truetype"),
    url("/fonts/Flaticon.svg#Flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$flaticon-map: (
    "angel": "\f101",
    "check": "\f102",
    "close": "\f103",
    "cloudy-night": "\f104",
    "config": "\f105",
    "crown": "\f106",
    "dead-body": "\f107",
    "farmer": "\f108",
    "gallows": "\f109",
    "hourglass": "\f10a",
    "seer": "\f10b",
    "skull": "\f10c",
    "sun": "\f10d",
    "sunset": "\f10e",
    "wolf": "\f10f",
);

.flaticon-angel:before {
    content: map-get($flaticon-map, "angel");
}
.flaticon-check:before {
    content: map-get($flaticon-map, "check");
}
.flaticon-close:before {
    content: map-get($flaticon-map, "close");
}
.flaticon-cloudy-night:before {
    content: map-get($flaticon-map, "cloudy-night");
}
.flaticon-config:before {
    content: map-get($flaticon-map, "config");
}
.flaticon-crown:before {
    content: map-get($flaticon-map, "crown");
}
.flaticon-dead-body:before {
    content: map-get($flaticon-map, "dead-body");
}
.flaticon-farmer:before {
    content: map-get($flaticon-map, "farmer");
}
.flaticon-gallows:before {
    content: map-get($flaticon-map, "gallows");
}
.flaticon-hourglass:before {
    content: map-get($flaticon-map, "hourglass");
}
.flaticon-seer:before {
    content: map-get($flaticon-map, "seer");
}
.flaticon-skull:before {
    content: map-get($flaticon-map, "skull");
}
.flaticon-sun:before {
    content: map-get($flaticon-map, "sun");
}
.flaticon-sunset:before {
    content: map-get($flaticon-map, "sunset");
}
.flaticon-wolf:before {
    content: map-get($flaticon-map, "wolf");
}
