body{
    font-family: $font-primary;
    background-color: $body;
}
::selection{
    background: $primary;
    color: $primary-contrast;
}
p, ul, li {
    font-family: $font-primary;
    color: $text-light;
}

p{
    font-size: 16px;
    line-height: 28px;
}

img{
    width: 100%;
    height: auto;
}

a{
    text-decoration: none;
    color: $primary;
    transition: color .2s;
    &:hover{
        text-decoration: none;
        color: $secondary;
    }
}

h1, h2, h3, h4, h5{
    font-family: $font-secondary;
    margin-top: 0px;
}

input[type=text]{
    background-color: transparent;
    border: 0px;
    color: $primary;
    &:focus{
        outline: none;
    }
}

button{
    &:focus{
        outline: none;
    }
}

header{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5;
    width: 100%;
    padding: 12px 0px;
    box-sizing: border-box;
    .container{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: stretch;
        align-items: center;
    }
    .countdown{
        .timesup, .timer{
            color: $primary-contrast;
            i{
                font-size: 24px;
                color: $primary-contrast;
                vertical-align: middle;
                margin-right: 5px;
            }
            span{
                vertical-align: middle;
            }
        }
    }
    .icon-phase{
        font-size: 64px;
        color: $primary-contrast;
    }
    .config{
        flex: 2 1 auto;
        cursor: pointer;
        p{
            display: inline-block;
            color: $primary-contrast;
            margin-bottom: 0px;
            margin-top: 0px;
            transition: color .2s;
        }
        i{
            display: inline-block;
            font-size: 24px;
            margin-left: 10px;
            vertical-align: -7px;
            transition: color .2s;
        }
        &:hover{
            p{
                color: $primary;
            }
            i{
                color: $primary;
            }
        }
    }
    @media screen and (max-width: $sm){
        background-color: $text;
        .countdown{
            .timesup, .timer{
                i{
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }
        .icon-phase{
            font-size: 24px;
            line-height: 24px;
        }
    }
}

.container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    @media (min-width: 576px){
        max-width: 540px;
    }
    @media (min-width: 768px){
        max-width: 720px;
    }
    // @media (min-width: 992px){
    //     max-width: 960px;
    // }
    // @media (min-width: 1200px){
    //     max-width: 1140px;
    // }
}

.main-body{
    padding-top: 104px;
    height: calc(100vh - 104px);
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    &.no-header{
        padding-top: 0px;
        height: 100vh;
    }
    >.container{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media screen and (max-width: $sm){
        padding-top: 52px;
        height: calc(100vh - 52px);
        >.container{
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

.phase-message{
    font-size: 42px;
    strong{
        color: $primary;
    }
    @media screen and (max-width: $sm){
        font-size: 28px;
    }
}

.phase-text{
    font-family: $font-primary;
    font-size: 26px;
    line-height: 42px;
    letter-spacing: 0.05em;
    text-align: justify;
    margin-bottom: 40px;
    strong{
        color: $primary;
    }
    &::first-letter{
        font-size: 370%;
        font-family: $font-secondary;
        float: left;
        margin-top: 35px;
        margin-right: 15px;
        margin-bottom: 0px;
    }
    @media screen and (max-width: $sm){
        font-size: 15px;
        line-height: 24px;
        &::first-letter{
            margin-top: 20px;
            margin-right: 10px;
        }
    }
}

.role-message{
    font-size: 26px;
    line-height: 42px;
    font-family: $font-primary;
    strong{
        color: $primary;
    }
    @media screen and (max-width: $sm){
        font-size: 15px;
        line-height: 24px;
    }
}

.icon-role{
    font-size: 104px;
    color: $primary-contrast;
    margin-bottom: 10px;
    @media screen and (max-width: $sm){
        font-size: 90px;
    }
}

.icon-message{
    i{
        font-size: 140px;
    }
    @media screen and (max-width: $sm){
        i{
            font-size: 90px;
        }
    }
}

.modal-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 14;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    &.fade{
        transition: opacity .15s linear;
        opacity: 0;
    }
    &.show{
        opacity: .5;
    }
}

.modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    opacity: 0;
    transition: opacity .3s, transform .3s;
    transform: translateY(-50px);
    &.modal-open{
        overflow-x: hidden;
        overflow-y: auto;
    }
    &.show{
        transform: translateY(0px);
        opacity: 1;
    }
    .modal-dialog{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        pointer-events: none;
        max-width: 500px;
        .modal-content{
            position: relative;
            display: flex;
            flex-direction: column;
            width: calc(100% - 80px);
            pointer-events: auto;
            background-color: $primary;
            background-clip: padding-box;
            border: none;
            border-radius: .3rem;
            outline: 0;
            padding: 40px;
            .modal-body{
                p{
                    color: $text;
                }
            }
            .modal-title{
                color: $text;
                font-size: 24px;
                line-height: 32px;
                font-weight: bold;
                font-family: $font-secondary;
                text-align: center;
                margin-bottom: 20px;
                border-bottom: 2px solid $text;
                padding-bottom: 10px;
            }
            .close{
                position: absolute;
                top: -10px;
                right: -10px;
                width: 50px;
                height: 50px;
                background-color: $primary;
                border: 2px solid $text;
                border-radius: 50%;
                color: $text;
                font-weight: 900;
                font-size: 20px;
                line-height: 28px;
                transition: background-color .2s, color .2s;
                cursor: pointer;
                &:hover{
                    background-color: $text;
                    color: $primary-contrast;
                }
            }
            .input-group-time{
                color: $text;
                font-family: $font-secondary;
                font-size: 24px;
                margin-bottom: 20px;
                .before{
                    display: inline-block;
                }
                .after{
                    display: inline-block;
                }
                input{
                    width: 60px;
                    margin: 0px 5px;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: right;
                    background-color: transparent;
                    border: none;
                    color: $text;
                    font-family: $font-secondary;
                    &:focus{
                        outline: 1px solid $primary;
                    }
                }
            }
            .saveButton{
                margin: 0 auto;
                display: block;
                padding: 12px 20px;
                border: 2px solid $text;
                background-color: transparent;
                color: $text;
                width: 100%;
                transition: background-color .2s, color .2s;
                font-family: $font-secondary;
                font-weight: bold;
                font-size: 18px;
                text-transform: uppercase;
                cursor: pointer;
                &:hover{
                    background-color: $text;
                    color: $primary-contrast;
                }
            }
        }
    }
    @media screen and (max-width: $md){
        .modal-dialog{
            width: calc(100% - 30px);
        }
    }
}