$primary: #b92d2d;
$primary-contrast: #ffffff;
$secondary: #348CCC;
$secondary-contrast: #ffffff;

$primary-gradient: linear-gradient(to right, #004f9a 0%,#007eb3 100%);
$primary-gradient-invert: linear-gradient(to right, #007eb3 0%,#004f9a 100%);

$secondary-gradient: linear-gradient(to right, #e67a10 0%,#f9b706 100%);
$secondary-gradient-invert: linear-gradient(to right, #f9b706 0%,#e67a10 100%);

$text: #000;
$text-light: #555;
$body: #ffffff;

$background-light: #eaf0f7; 
$background-dark: #e4e9ee;

$success: #06be59;
$danger: #ff5656;
$warning: #fdd03d;

$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Crimson Text', serif;

$border-radius: 5px;