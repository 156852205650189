#Room{
    width: 100%;
    height: 100%;
}

#StartScreen{
    .content{
        .game-logo{
            margin-bottom: 40px;
            .game-name{
                font-size: 64px;
                line-height: 72px;
                text-transform: uppercase;
                margin-bottom: 0px;
                margin-top: 0px;
            }
            .game-sub{
                text-transform: uppercase;
            }
        }
        .form-section{
            label{
                font-family: $font-secondary;
                font-size: 24px;
                display: block;
                margin-bottom: 40px;
            }
            .input-name{
                position: relative;
                input{
                    text-align: center;
                    display: block;
                    width: calc(100% - 80px);
                    padding: 20px 40px;
                    font-size: 42px;
                    line-height: 52px;
                    font-family: $font-secondary;
                }
                &:before{
                    content: 'x';
                    font-size: 32px;
                    line-height: 32px;
                    font-family: $font-secondary;
                    color: $primary;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:after{
                    content: '';
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    bottom: 25px;
                    left: 0px;
                    background-color: $primary;
                }
            }
        }
    }
    @media screen and (max-width: $sm){
        .content{
            .game-logo{
                margin-bottom: 25px;
                .game-name{
                    font-size: 42px;
                    line-height: 48px;
                }
            }
        }
    }
}

#WaitingRoom{
    .playersList{
        list-style: none;
        margin: 0px;
        padding: 0px;
        li{
            border-bottom: 2px solid $primary;
            padding: 10px 20px;
            font-size: 24px;
            line-height: 28px;
            color: $primary;
            margin-bottom: 20px;
            position: relative;
            &:first-child{
                border: 2px solid $primary;
                &:after{
                    content: '\f106';
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 24px;
                    display: inline-block;
                    font-family: "Flaticon";
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    line-height: 1;
                    text-decoration: inherit;
                    text-rendering: optimizeLegibility;
                    text-transform: none;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    font-smoothing: antialiased;
                }
            }
        }
    }
    .waitMessage{
        margin-top: 40px;
        padding: 10px 20px;
        border: 2px solid $primary-contrast;
        opacity: 0.4;
        animation: flickerAnimation 2s infinite;
        @keyframes flickerAnimation {
            0%   { opacity:1; }
            50%  { opacity:0.2; }
            100% { opacity:1; }
        }
    }
}

#NightPhase{
    background-image: url(/images/night-background.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    .seer-name{
        font-size: 42px;
        line-height: 48px;
        font-family: $font-secondary;
    }
    &:before{
        content: '';
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
    }
    .killList{
        padding: 0px;
        margin: 0px;
        button{
            background-color: transparent;
            border: none;
            position: relative;
            display: block;
            margin: 0 auto;
            padding: 0px;
            width: 100%;
            text-align: left;
            transition: background-color .2s;
            cursor: pointer;
            i{
                font-size: 24px;
                vertical-align: middle;
                color: $primary-contrast;
                opacity: 0;
                transition: opacity .2s;
                padding: 15px;
            }
            span{
                vertical-align: middle;
                color: $primary-contrast;
                padding: 15px;
                display: inline-block;
                border-left: 2px solid $text;
                font-size: 18px;
            }
            &.active{
                background-color: $primary;
                i{
                    opacity: 1;
                }
            }
            &:hover{
                background-color: $primary;
            }
        }
    }
}

#NightPhaseResolution{
    background-image: url(/images/day-background.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    &:before{
        content: '';
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
    }
}

#DayPhase{
    background-image: url(/images/day-background.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    &:before{
        content: '';
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
    }
    .hangList{
        padding: 0px;
        margin: 0px;
        margin-bottom: 30px;
        .hangButton{
            background-color: transparent;
            border: none;
            position: relative;
            display: block;
            margin: 0 auto;
            padding: 0px;
            width: 100%;
            text-align: left;
            transition: background-color .2s;
            cursor: pointer;
            .check{
                font-size: 24px;
                vertical-align: middle;
                color: $primary-contrast;
                opacity: 0;
                transition: opacity .2s;
                padding: 15px;
            }
            .vote{
                position: absolute;
                right: 15px;
                top: 15px;
                i{
                    font-size: 18px;
                    vertical-align: middle;
                    color: $primary-contrast;
                    padding: 15px 5px;
                    opacity: 0;
                    animation: fadein 1s;
                    animation-fill-mode: forwards;
                    @keyframes fadein {
                        from { opacity: 0; }
                        to   { opacity: 1; }
                    }
                }
            }
            span{
                vertical-align: middle;
                color: $primary-contrast;
                padding: 15px;
                display: inline-block;
                border-left: 2px solid $text;
                font-size: 18px;
            }
            &.active{
                background-color: $primary;
                .check{
                    opacity: 1;
                }
            }
            &:hover{
                background-color: $primary;
            }
        }
    }
}

#DayPhaseResolution{
    background-image: url(/images/day-background.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    &:before{
        content: '';
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
    }
}

#EndGame{
    width: 100%;
    height: 100%;
    &:before{
        content: '';
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
    }
}